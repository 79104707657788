import React, { useCallback, useEffect, useMemo, forwardRef } from 'react'
import clsx from 'clsx'
import {
	useToaster,
	useToasterStore,
	toast,
	ToastOptions as RHTToastOptions,
	Toast as RHTToast
} from 'react-hot-toast'
interface ToastOptions {
	icon?: string
}

const hotToastConfig: RHTToastOptions = {
	position: 'bottom-right',
	// duration: 10000,
	// duration: 3000000,
}

export function useToast() {
	const info = useCallback((message: any, opts?: ToastOptions) => {
		toast(
			<ToastContent
				message={message}
				{...opts}
				icon={opts?.icon ?? 'accept'}
			/>,
			{
				className: 'toast--info',
				...hotToastConfig,
			},
		)
	}, [])

	const show = info

	const success = useCallback((message: any, opts?: ToastOptions) => {
		toast(
			<ToastContent message={message} {...opts} icon={opts?.icon ?? 'check'} />,
			{
				className: 'toast--success',
				...hotToastConfig,
			},
		)
	}, [])

	const error = useCallback((message: any, opts?: ToastOptions) => {
		toast(
			<ToastContent
				message={message}
				{...opts}
				icon={opts?.icon ?? 'warning'}
			/>,
			{
				className: 'toast--error',
				...hotToastConfig,
			},
		)
	}, [])

	return { show, info, success, error }
}

interface ToastContentProps {
	message: any
	icon?: string
}

const ToastContent = forwardRef<HTMLDivElement, ToastContentProps>(
	function ToastContent({ message, icon }, ref) {
		// console.debug('content', icon, message)
		return (
			<div className="toast__content" ref={ref}>
				<span
					className={clsx('toast__message', {
						[`icon:${icon} icon--${icon}`]: icon,
					})}
				>
					{message}
				</span>
				{/* <div className="toast__actions">
				<div className="toast__action">rückgängig machen</div>
			</div> */}
			</div>
		)
	},
)

interface ToastProps extends RHTToast {
	// id: string
	children: RHTToast['message']
	// visible: boolean
	offset: number
	// ariaProps: any
	// className: string
	// type: string
}

const Toast = forwardRef<HTMLDivElement, ToastProps>(
	function Toast(
		{ id, children, visible, offset, ariaProps, className, type, ...props },
		ref,
	) {
		const style = useMemo(() => {
			let transform = `translateY(${offset * -1}px)`
			if (!visible) {
				transform = `translateY(${offset * -1}px) translateX(4rem)`
			}
			return {
				opacity: visible ? 1 : 0,
				transform,
			}
		}, [visible, offset])

		return (
			<div
				ref={ref}
				className={clsx('toast', className, {
					[`toast--${type}`]: type,
				})}
				style={style}
				{...ariaProps}
				onClick={() => toast.dismiss(id)}
			>
				{children as React.ReactNode}
			</div>
		)
	},
)

const TOAST_LIMIT = 5
// type Renderable = JSX.Element | string | null;
// type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;
// type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;
// type ToastMessage = ValueOrFunction<Renderable, Toast>

// see https://github.com/timolins/react-hot-toast/blob/main/src/components/toaster.tsx

export function ToastProvider({ children }) {
	const { toasts, handlers } = useToaster()
	// const { startPause, endPause, calculateOffset, updateHeight } = handlers
	// console.debug('render toasts', toasts, handlers)

	useEffect(() => {
		toasts
			.filter((t) => t.visible)
			.filter((_, i) => i >= TOAST_LIMIT)
			.forEach((t) => toast.dismiss(t.id))
	}, [toasts])

	return (
		<>
			<div
				className="toasts"
				onMouseEnter={handlers.startPause}
				onMouseLeave={handlers.endPause}
			>
				{toasts.map((toast, index) => {
					const offset = handlers.calculateOffset(toast, {
						reverseOrder: false,
						gutter: 12,
						// defaultPosition: 'bottom-right',
					})
					const ref = (el: any) => {
						if (el && !toast.height) {
							const height = el.getBoundingClientRect().height
							handlers.updateHeight(toast.id, height)
						}
					}
					// console.debug(toast)
					return (
						<Toast ref={ref} key={toast.id} {...toast} offset={offset}>
							{toast.message}
						</Toast>
					)
				})}
			</div>
			{children}
		</>
	)
}