import React from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NextIntlClientProvider } from 'next-intl'
import { SSRProvider, OverlayProvider, I18nProvider } from 'react-aria'
import { ToastProvider } from '#hh/client/components/overlays/Toast'
import { PageProps } from '#hh/client/types'
import '../../less/build.less'
import { chooseNavigatorLang } from '#hh/client/lib'
import { availableLocales, defaultLocale } from '#hh/shared/intl'

function App(props: AppProps<PageProps>) {
	const { Component, pageProps } = props
	const router = useRouter()

	const PageComponent: any = Component
	const getLayout = PageComponent.getLayout || ((page) => page)

	const targetLang =
		pageProps.user?.language ??
		chooseNavigatorLang(availableLocales) ??
		defaultLocale

	// console.log('user lang', userLang)
	if (
		typeof window !== 'undefined' &&
		targetLang &&
		targetLang !== router.locale
	) {
		// if (typeof window !== 'undefined' && userLang !== router.locale) {
		// console.debug('user lang redirect', {
		// 	userLanguage: pageProps.user?.language,
		// 	routerLocale: router.locale,
		// })
		router.replace(router.asPath, undefined, { locale: targetLang })
	}

	return getLayout(
		<NextIntlClientProvider
			locale={router.locale}
			timeZone="Europe/Vienna"
			messages={pageProps.messages}
		>
			<SSRProvider>
				<Head>
					<meta charSet="utf-8" />
					<meta httpEquiv="x-ua-compatible" content="ie=edge" />
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0"
					/>
					<meta name="mobile-web-app-capable" content="yes" />
				</Head>
				<I18nProvider locale={router.locale}>
					<OverlayProvider>
						<ToastProvider>
							<PageComponent {...pageProps} />
						</ToastProvider>
					</OverlayProvider>
				</I18nProvider>
			</SSRProvider>
		</NextIntlClientProvider>,
		pageProps,
	)
}

export default App
