import { getLanguage } from '#hh/shared/lib'
import { find } from 'lodash'

export const guessLanguageCountry = (language: string): string => {
	const normalizedLang = getLanguage(language)
	const code = normalizedLang?.iso6391

	if (!code) {
		return
	}
	if (languageToCountry[code]) {
		return languageToCountry[code]
	}

	const foundCountry = find(
		Object.entries<any>(countryToLanguage),
		([country, lang]) => {
			return lang === code
		},
	)

	if (foundCountry) {
		return foundCountry[0]
	}
}

/**
 * Choose the best available locale based on the window.navigator.languages property
 * @param languages - An array of language strings.
 * @param availableLocales - An array of available locales.
 */
export function chooseNavigatorLang(availableLocales: string[]) {
	if (typeof window === 'undefined') {
		return
	}
	const langs = window.navigator.languages.map((lang) => lang.split('-')[0])

	const available = langs.filter((lang) => availableLocales.includes(lang))
	return available[0]
}

// https://github.com/wix/locale-helpers/blob/master/src/Languages.js
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes

const languageToCountry = {
	af: 'ZA',
	ar: 'EG',
	bn: 'BD',
	bo: 'CN',
	bs: 'BA',
	ca: 'ES',
	da: 'DK',
	de: 'DE',
	ee: 'GH',
	el: 'GR',
	en: 'US',
	es: 'ES',
	fa: 'IR',
	ff: 'MR',
	fo: 'DK',
	fr: 'FR',
	gu: 'IN',
	he: 'IL',
	ha: 'NG',
	hr: 'HR',
	it: 'IT',
	ko: 'KR',
	ln: 'CD',
	ms: 'MY',
	nb: 'NO',
	ne: 'NP',
	nl: 'NL',
	om: 'ET',
	os: 'GE',
	pa: 'IN',
	pt: 'BR',
	qu: 'PE',
	ro: 'RO',
	ru: 'RU',
	se: 'NO',
	so: 'SO',
	sq: 'AL',
	sr: 'RS',
	sv: 'SE',
	sw: 'TZ',
	ta: 'IN',
	ti: 'ET',
	tr: 'TR',
	ur: 'IN',
	uz: 'UZ',
	yo: 'NG',
	zh: 'CN',
	te: 'IN',
	no: 'NO',
	eu: 'ES',
	jv: 'ID',
	ml: 'IN',
	mr: 'IN',
	ug: 'CN',
	tl: 'PH',
	cy: 'GB-WLS',
}

const countryToLanguage = {
	AD: 'ca',
	AE: 'ar',
	AF: 'ps',
	AG: 'en',
	AI: 'en',
	AL: 'sq',
	AM: 'hy',
	AO: 'pt',
	AR: 'es',
	AS: 'en',
	AT: 'de',
	AU: 'en',
	AW: 'nl',
	AX: 'sv',
	AZ: 'az',
	BA: 'bs',
	BB: 'en',
	BD: 'bn',
	BE: 'nl',
	BF: 'fr',
	BG: 'bg',
	BH: 'ar',
	BI: 'fr',
	BJ: 'fr',
	BL: 'fr',
	BM: 'en',
	BN: 'ms',
	BO: 'es',
	BQ: 'nl',
	BR: 'pt',
	BS: 'en',
	BT: 'dz',
	BW: 'en',
	BY: 'be',
	BZ: 'en',
	CA: 'en',
	CC: 'en',
	CD: 'fr',
	CF: 'fr',
	CG: 'fr',
	CH: 'de',
	CI: 'fr',
	CK: 'en',
	CL: 'es',
	CM: 'en',
	CN: 'zh',
	CO: 'es',
	CR: 'es',
	CU: 'es',
	CV: 'pt',
	CW: 'nl',
	CX: 'en',
	CY: 'el',
	CZ: 'cs',
	DE: 'de',
	DJ: 'fr',
	DK: 'da',
	DM: 'en',
	DO: 'es',
	DZ: 'ar',
	EC: 'es',
	EE: 'et',
	EG: 'ar',
	EH: 'es',
	ER: 'ti',
	ES: 'es',
	ET: 'am',
	FI: 'fi',
	FJ: 'en',
	FK: 'en',
	FM: 'en',
	FO: 'fo',
	FR: 'fr',
	GA: 'fr',
	GB: 'en',
	GD: 'en',
	GE: 'ka',
	GF: 'fr',
	GG: 'en',
	GH: 'en',
	GI: 'en',
	GL: 'kl',
	GM: 'en',
	GN: 'fr',
	GP: 'fr',
	GQ: 'es',
	GR: 'el',
	GS: 'en',
	GT: 'es',
	GU: 'en',
	GW: 'pt',
	GY: 'en',
	HK: 'zh',
	HM: 'en',
	HN: 'es',
	HR: 'hr',
	HT: 'fr',
	HU: 'hu',
	ID: 'id',
	IE: 'ga',
	IL: 'he',
	IM: 'en',
	IN: 'hi',
	IO: 'en',
	IQ: 'ar',
	IR: 'fa',
	IS: 'is',
	IT: 'it',
	JE: 'en',
	JM: 'en',
	JO: 'ar',
	JP: 'ja',
	KE: 'en',
	KG: 'ky',
	KH: 'km',
	KI: 'en',
	KM: 'ar',
	KN: 'en',
	KP: 'ko',
	KR: 'ko',
	KW: 'ar',
	KY: 'en',
	KZ: 'kk',
	LA: 'lo',
	LB: 'ar',
	LC: 'en',
	LI: 'de',
	LK: 'si',
	LR: 'en',
	LS: 'en',
	LT: 'lt',
	LU: 'fr',
	LV: 'lv',
	LY: 'ar',
	MA: 'ar',
	MC: 'fr',
	MD: 'ro',
	ME: 'sr',
	MF: 'en',
	MG: 'fr',
	MH: 'en',
	MK: 'mk',
	ML: 'fr',
	MM: 'my',
	MN: 'mn',
	MO: 'zh',
	MP: 'en',
	MQ: 'fr',
	MR: 'ar',
	MS: 'en',
	MT: 'mt',
	MU: 'en',
	MV: 'dv',
	MW: 'en',
	MX: 'es',
	MY: 'ms',
	MZ: 'pt',
	NA: 'en',
	NC: 'fr',
	NE: 'fr',
	NF: 'en',
	NG: 'en',
	NI: 'es',
	NL: 'nl',
	NO: 'nb',
	NP: 'ne',
	NR: 'en',
	NU: 'en',
	NZ: 'en',
	OM: 'ar',
	PA: 'es',
	PE: 'es',
	PF: 'fr',
	PG: 'en',
	PH: 'en',
	PK: 'en',
	PL: 'pl',
	PM: 'fr',
	PN: 'en',
	PR: 'es',
	PS: 'ar',
	PT: 'pt',
	PW: 'en',
	PY: 'es',
	QA: 'ar',
	RE: 'fr',
	RO: 'ro',
	RS: 'sr',
	RU: 'ru',
	RW: 'rw',
	SA: 'ar',
	SB: 'en',
	SC: 'fr',
	SD: 'ar',
	SE: 'sv',
	SG: 'en',
	SH: 'en',
	SI: 'sl',
	SJ: 'nb',
	SK: 'sk',
	SL: 'en',
	SM: 'it',
	SN: 'fr',
	SO: 'so',
	SR: 'nl',
	SS: 'en',
	ST: 'pt',
	SV: 'es',
	SX: 'nl',
	SY: 'ar',
	SZ: 'en',
	TC: 'en',
	TD: 'fr',
	TF: 'fr',
	TG: 'fr',
	TH: 'th',
	TJ: 'tg',
	TK: 'en',
	TL: 'pt',
	TM: 'tk',
	TN: 'ar',
	TO: 'en',
	TR: 'tr',
	TT: 'en',
	TV: 'en',
	TW: 'zh',
	TZ: 'sw',
	UA: 'uk',
	UG: 'en',
	UM: 'en',
	US: 'en',
	UY: 'es',
	UZ: 'uz',
	VA: 'it',
	VC: 'en',
	VE: 'es',
	VG: 'en',
	VI: 'en',
	VN: 'vi',
	VU: 'bi',
	WF: 'fr',
	WS: 'sm',
	XK: 'sq',
	YE: 'ar',
	YT: 'fr',
	ZA: 'af',
	ZM: 'en',
	ZW: 'en',
}
